<template>
  <b-col lg="6" md="12" class="gift-card">
    <div class="gift-card-inner">
      <div class="gift-left">
        <router-link :to="{name: 'users.show', params: {id: gift.user.id}}">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                  size="48"
                  :src="gift.user.profile.image"
                  :text="avatarText(gift.user.userName)"
              />
            </template>
          </b-media>
        </router-link>
        <div class="gift-details">
          <router-link :to="{name: 'users.show', params: {id: gift.user.id}}" class="gift-link">
            @{{ gift.user.userName }}
          </router-link>
          <div class="gift-time">
            {{ getDate(gift.createdAt) }}
          </div>
        </div>
      </div>
      <div class="gift-right">
        <span v-if="userGender === 'male'" class="gift-credit">
          <b-img :src="iconDiamonds1" width="18" height="18" />
          {{ gift.credits }}
        </span>
        <div class="gift-image">
          <b-img widtg="64" :alt="gift.label" :src="getImgGift(gift.image)" width="38"/>
        </div>
      </div>
    </div>
  </b-col>
</template>

<script>
import { BAvatar, BCol, BImg, BMedia } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import {showNotify, throwDefaultError} from '@/helpers/helpers'
import moment from 'moment/moment'
import { avatarText } from '@core/utils/filter'
import _ from "lodash";

export default {
  name: 'gift-card',
  props: ['gift', 'type'],
  components: {
    BAvatar,
    BCol,
    BImg,
    BMedia
  },
  data() {
    return {
      iconDiamonds1: require('@/assets/images/icons/diamonds-1.svg'),
    }
  },
  computed: {
    userGender() {
      return this.$store.getters['auth/userGender']
    }
  },
  methods: {
    avatarText(text) {
      return avatarText(text)
    },
    getDate(date) {
      return moment(date).format('DD.MM.YYYY HH:mm:ss')
    },
    getDateForHuman(timeLeft) {
      if (timeLeft <= 0) {
        return '0 ' + this.$t('seconds')
      }

      let result = ''
      let totalSeconds = timeLeft;

      const days = Math.floor(totalSeconds / (60 * 60 * 24))
      totalSeconds -= days * (60 * 60 * 24)

      const hours = Math.floor(totalSeconds / (60 * 60))
      totalSeconds -= hours * (60 * 60)

      const minutes = Math.floor(totalSeconds / (60))
      totalSeconds -= minutes * 60

      const seconds = Math.floor(totalSeconds % 60)

      if (days > 0) {
        result += days + ` ${this.$t('days')}, `
      }

      if (hours > 0) {
        result += hours + ` ${this.$t('hours')}, `
      }

      if (minutes > 0) {
        result += minutes + ` ${this.$t('minutes')}, `
      }

      if (seconds > 0) {
        result += seconds + ` ${this.$t('seconds')}, `
      }

      return _.trimEnd(result, ', ')
    },
    getImgGift(name) {
      return require('@/assets/images/gifts/' + name)
    },
  }
}
</script>

<style lang="scss" scoped>
.gift-card {
  background-color: #F5F8FD;
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 10px;

  .gift-card-inner {
    display: flex;
    justify-content: space-between;

    .gift-left {
      display: flex;
      align-items: center;

      .gift-details {
        .gift-link {
          font-family: 'Lexend', sans-serif;
          font-size: 18px;
          font-style: normal;
          color: #000000;
          font-weight: 600;
          line-height: 22px;
        }
      }
      .gift-time {
        font-family: 'Lexend', sans-serif;
        color: #A4A4A4;
        margin-top: 4px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;

      }
    }

    .gift-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 8px;

      .gift-credit {
        padding: 5px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 100px;
        font-family: 'Lexend', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
        color: #000000;
        margin-top: 2px;
      }

      .gift-image {
        display: flex;
        justify-content: center;
        width: 50px;
      }
    }
  }
}
</style>
