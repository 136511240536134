<template>
  <section class="page-interaction">
    <b-overlay :show="loading" no-center variant="transparent" rounded="sm">
      <template #overlay>
        <loading-spinner />
      </template>
      <b-tabs content-class="mt-3" v-model="activeTabIndex">
        <b-tab :title="$t('Gifts I Received')">
          <b-row v-for="(receivedGift, i) in receivedGifts" :key="i">
            <gift-card :gift="receivedGift" @setLoading="setLoading($event)"/>
          </b-row>
          <b-row v-if="!loading && receivedGifts.length < 1">
            <discover-no-user :show-image="false">
              <h3 class="color-black">
                {{ $t('You haven\'t received a gift yet') }}
              </h3>
            </discover-no-user>
          </b-row>
        </b-tab>
        <b-tab :title="$t('Gifts I\'ve Sent')">
          <b-row v-for="(sentGift, i) in sentGifts" :key="i">
            <gift-card :gift="sentGift" type="sentGift" @setLoading="setLoading($event)"/>
          </b-row>
          <b-row v-if="!loading && sentGifts.length < 1">
            <discover-no-user :show-image="false">
              <h3 class="color-black">
                {{ $t('You haven\'t sent a gift to anyone yet') }}
              </h3>
            </discover-no-user>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-overlay>
  </section>
</template>

<script>
import {
  BAvatar, BCol, BImg, BMedia, BOverlay, BRow, BTabs, BTab,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { throwDefaultError } from '@/helpers/helpers'
import DiscoverNoUser from '@/components/DiscoverNoUser'
import moment from 'moment'
import GiftCard from '@/components/GiftCard'

// import { getUser } from '@/auth/utils'

export default {
  name: 'GiftsIndex',
  components: {
    BAvatar,
    BCol,
    BImg,
    BMedia,
    BOverlay,
    BRow,
    BTabs,
    BTab,

    DiscoverNoUser,
    GiftCard
  },
  data() {
    return {
      activeTabIndex: 0,
      limit: 200,
      loading: false,
      receivedGifts: [],
      sentGifts: [],
    }
  },
  created() {
    this.getSentGifts()
    this.getReceivedGifts()
  },
  methods: {
    getImgGift(name) {
      return require('@/assets/images/gifts/' + name)
    },
    getReceivedGifts() {
      this.loading = true
      useJwt.getReceivedGifts(0, this.limit)
          .then(response => {
            this.receivedGifts = response.data.body.map(gift => {
              gift['user'] = gift['ownerUser']
              return gift
            })
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => { this.loading = false })
    },
    getSentGifts() {
      this.loading = true
      useJwt.getSentGifts(0, this.limit)
          .then(response => {
            this.sentGifts = response.data.body.map(gift => {
              gift['user'] = gift['targetUser']
              return gift
            })
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => { this.loading = false })
    },
    getDate(date) {
      return moment(date).format('DD.MM.YYYY HH:mm:ss')
    },
    setLoading($event) {
      this.loading = $event
    },
  }
}
</script>
